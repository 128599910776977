.SubmitFiles {
    font-size: 2rem;
}
.SubmitFiles > * {
    margin: 0.5rem;
}

.SubmitButton {
    font-size: 2rem;
    background-color: var(--accent);
}
input[type="text"]
{
    font-size: 2rem;
}

.SubmitterForm > * {
    margin: 0.5rem;
}

.SubmissionDetails {
    display: flex;
}