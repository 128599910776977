:root {
  --text: #F0F0F0;
  --text-shadow: #D9D9D9;
  --background: #2E445B;
  --primary: #FF8200;/*#FFAE00;*//*#ca9b36;*/
  --secondary: #553a00;
  --accent: #ca8900;
/*--antiflash-white: #F0F0F0ff;
--charcoal: #2E445Bff;
--flax: #F0DC8Dff;
--dark-goldenrod: #C58600ff;
--harvest-gold: #C88409ff;*/
}
@font-face {
  font-family: 'Commercial';
  src: url(../public/commesmn.ttf) format("ttf"),
      url(../public/commesmn.eot) format("eot"),
      url(../public/commesmn.svg) format("svg"),
      url(../public/commesmn.woff) format("woff"),
      url(../public/commesmn.woff2) format("woff2"),
}

html *
{
   color: var(--text);
   font-family: Inter;
   background-color: var(--background);
   
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

 

@media (min-width: 1000px) {
  html {
      font-size: 16px;
  }
}

@media (max-width: 1000px) {
  html {
      font-size: 10px;
  }
}